<template>
  <div>
    <div class="text-h6 font-weight-medium mb-4">{{ $t("pack.title") }}</div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 pt-3 pb-2 flex-wrap">
        <v-btn depressed @click="refreshItems()" :loading="loading.refresh" class="mr-2 mb-2">
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="filterItems()" class="mb-2 mr-2" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <div :class="{'mr-auto': $vuetify.breakpoint.xsOnly, 'ml-auto': $vuetify.breakpoint.smAndUp }" class="mb-2">
          <v-btn color="primary" @click="addPack()">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            <span>{{ $t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card>
      <v-card-title>
        <div class="font-weight-bold">{{ $t('pack.list.title') }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
        :server-items-length="meta.total"
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px;">
                    <span>{{ item.application.title }}</span>
                  </td>
                  <td>
                    <v-chip
                      class="table-chips"
                      color="success"
                      v-if="item.active"
                    >{{ $t("pack.fields.active.activated") }}</v-chip>
                    <v-chip class="table-chips" color="success" v-else>
                      {{
                      $t("pack.fields.active.deactivated")
                      }}
                    </v-chip>
                  </td>
                  <td>
                    <span>{{ item.title }}</span>
                  </td>
                  <td>
                    <span>{{ item.quantity }} {{ item.unit.toUpperCase() }}</span>
                  </td>
                  <td>
                    <span>{{ item.price }}</span>
                  </td>
                  <td style="min-width: 150px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="editPack(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                        >
                          <v-icon color="primary">mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('pack.btn.edit.title') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="deletePack(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('pack.btn.delete.title') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <pack-form :dialog="dialog.form" :pack="pack" :form="form" @success="refreshItems"></pack-form>
    <pack-confirm-delete :dialog="dialog.delete" @confirm="confirmDeletePack"></pack-confirm-delete>
  </div>
</template>

<script>
import { PackFormConst } from "./../../../constants/pack";
import PackForm from "./form";
import PackConfirmDelete from "./../../commons/confirm";
import ListMixin from "./../../../mixins/commons/list";
export default {
  mixins: [ListMixin],
  data: () => ({
    path: "/api/packs",
    dialog: {
      form: {
        title: null,
        display: false
      },
      delete: {
        display: false,
        message: null
      }
    },
    form: Object.assign({}, PackFormConst),
    pack: {}
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("pack.fields.application.title"),
          align: "start",
          sortable: true,
          value: "application.title",
          width: 160
        },
        {
          text: this.$t("pack.fields.active.title2"),
          align: "start",
          sortable: false,
          value: "active",
          width: 100
        },
        {
          text: this.$t("pack.fields.title.title"),
          align: "start",
          sortable: false,
          value: "title"
        },
        {
          text: this.$t("pack.fields.quantity.title2"),
          align: "start",
          sortable: false,
          value: "quantity",
          width: 120
        },
        {
          text: this.$t("pack.fields.price.title"),
          align: "start",
          sortable: false,
          value: "price",
          width: 120
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
          width: 150
        }
      ];
    },
    addPack() {
      this.dialog.form = {
        display: true,
        title: this.$t("pack.add.title")
      };
      this.pack = {};
      this.form = Object.assign({}, PackFormConst);
    },
    editPack(item) {
      this.pack = item;
      this.form = {
        title: item.title,
        code: item.code,
        application: item.application.id,
        price: item.price,
        quantity: item.quantity,
        unit: item.unit,
        description: item.description,
        active: item.active,
        color: item.color
      };
      this.dialog.form = {
        display: true,
        title: this.$t("pack.edit.title")
      };
    },
    deletePack(item) {
      this.pack = item;
      this.dialog.delete = {
        display: true,
        message: this.$t("pack.delete.confirm")
      };
    },
    async confirmDeletePack() {
      this.loading.delete = true;
      try {
        await this.request({
          url: `/api/packs/${this.pack.id}`,
          method: "delete",
          messages: {
            500: true,
            204: this.$t('pack.delete.success'),
            422: this.$t('pack.delete.unable')
          }
        });
        this.refreshItems();
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    }
  },
  components: {
    PackForm,
    PackConfirmDelete
  }
};
</script>