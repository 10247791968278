<template>
  <v-dialog v-model="dialog.display" max-width="550">
    <v-card>
      <v-card-title>
        <span class="text-subtitle-1"
          ><span class="secondary-font fon-weight-bold">{{
            dialog.title
          }}</span></span
        >
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0 mb-3 px-8">
        <v-row class="py-0 mt-0">
          <v-col cols="12" md="6" class="pt-0">
            <v-select
              :label="$t('pack.fields.application.title')"
              :placeholder="$t('pack.fields.application.description')"
              :items="items.applications"
              v-model="form.application"
              append-icon="mdi-application"
              class="font-weight-bold"
              item-text="title"
              item-value="id"
              @input="delayTouch($v.form.application)"
              @blur="$v.form.application.$touch"
              :error-messages="applicationErrors"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              :label="$t('pack.fields.color.title')"
              v-model="form.color"
              autocomplete="off"
              class="font-weight-bold"
              :error-messages="colorErrors"
              @input="delayTouch($v.form.color)"
              @blur="$v.form.color.$touch"
              readonly
              v-mask="mask.color"
            >
              <template v-slot:append>
                <v-menu
                  v-model="colorPickerMenu"
                  top
                  nudge-bottom="105"
                  nudge-left="16"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      :style="swatchStyle(colorPickerMenu, form.color)"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker mode="hexa" v-model="form.color" flat />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-0 mt-0">
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              :label="$t('pack.fields.title.title')"
              v-model="form.title"
              append-icon="mdi-text"
              class="font-weight-bold"
              @input="delayTouch($v.form.title)"
              @blur="$v.form.title.$touch"
              :error-messages="titleErrors"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              :label="$t('pack.fields.code.title')"
              v-model="form.code"
              append-icon="mdi-text-short"
              class="font-weight-bold"
              @input="delayTouch($v.form.code)"
              @blur="$v.form.code.$touch"
              :error-messages="codeErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-0 mt-0">
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              :label="$t('pack.fields.quantity.title')"
              v-model="form.quantity"
              append-icon="mdi-cash"
              class="font-weight-bold"
              @input="delayTouch($v.form.quantity)"
              @blur="$v.form.quantity.$touch"
              :error-messages="quantityErrors"
              :hint="$t('pack.fields.quantity.help')"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              :label="$t('pack.fields.price.title')"
              v-model="form.price"
              append-icon="mdi-counter"
              type="number"
              min="1"
              class="font-weight-bold"
              @input="delayTouch($v.form.price)"
              @blur="$v.form.price.$touch"
              :error-messages="priceErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-select
          :label="$t('pack.fields.unit.title')"
          v-model="form.unit"
          append-icon="mdi-text-short"
          class="font-weight-bold"
          @input="delayTouch($v.form.unit)"
          @blur="$v.form.unit.$touch"
          :error-messages="unitErrors"
          :items="items.units"
        ></v-select>
        <v-textarea
          :label="$t('pack.fields.description.title')"
          rows="2"
          v-model="form.description"
          append-icon="mdi-card-text-outline"
          class="font-weight-bold"
          no-resize
          @input="delayTouch($v.form.description)"
          @blur="$v.form.description.$touch"
          :error-messages="descriptionErrors"
        ></v-textarea>
        <v-checkbox
          color="primary"
          v-model="form.active"
          :label="$t('pack.fields.active.title')"
          class="font-weight-bold"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog.display = false" text>
          {{ $t("btn.close") }}
        </v-btn>
        <v-btn
          @click="save()"
          color="primary"
          class="font-weight-bold"
          text
          :disabled="$v.form.$invalid"
          :loading="loading"
          >{{ $t(pack.id ? "btn.update" : "btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormDelayMixin from "./../../../mixins/form-delay-touch";
import { PackFormConst } from "./../../../constants/pack";
import { mapActions } from "vuex";
import { required, maxLength, helpers } from "vuelidate/lib/validators";

export default {
  mixins: [FormDelayMixin],
  created() {
    this.setApplicationItems();
  },
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          title: null,
        };
      },
    },
    form: {
      type: Object,
      default: function () {
        return Object.assign({}, PackFormConst);
      },
    },
    pack: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: null,
          image: null,
        };
      },
    },
  },
  data: () => ({
    items: {
      applications: [],
      units: [{ text: "SMS", value: "sms" }],
    },
    loading: false,
    mask: {
      color: [
        /#/,
        /[0-9A-F]/,
        /[0-9A-F]/,
        /[0-9A-F]/,
        /[0-9A-F]/,
        /[0-9A-F]/,
        /[0-9A-F]/,
      ],
    },
    colorPickerMenu: null,
  }),
  validations() {
    const validations = {
      form: {
        application: {
          required,
        },
        title: {
          required,
          maxLength: maxLength(100),
          isUnique: async (value) => {
            if (!helpers.req(value)) return true;
            try {
              await this.request({
                url: "/api/packs/title-exists",
                method: "post",
                data: { title: value, packId: this.pack.id },
              });
              return false;
            } catch (error) {
              return true;
            }
          },
        },
        code: {
          required,
          maxLength: maxLength(20),
          isUnique: async (value) => {
            if (!helpers.req(value)) return true;
            try {
              await this.request({
                url: "/api/packs/code-exists",
                method: "post",
                data: { code: value, packId: this.pack.id },
              });
              return false;
            } catch (error) {
              return true;
            }
          },
        },
        price: {
          required,
          price: helpers.regex("price", /^\d+$/),
        },
        quantity: {
          required,
          quantity: (value) =>
            !helpers.req(value) || this.$utils.validateQuantityPack(value),
        },
        color: {
          required,
          color: helpers.regex("color", /^#([0-9a-f]{3}|[0-9a-f]{6})$/i),
        },
        unit: {
          required,
          unit: helpers.regex("unit", /^sms$/),
        },
        description: {
          required,
          maxLength: maxLength(255),
        },
      },
    };
    return validations;
  },
  methods: {
    swatchStyle(menu, color) {
      return this.$utils.swatchStyle(menu, color);
    },
    async save() {
      let url;
      let method;
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("error_codes.bad_request") });
        return;
      }
      if (this.pack.id) {
        url = `/api/packs/${this.pack.id}`;
        method = "put";
      } else {
        url = "/api/packs";
        method = "post";
      }
      this.loading = true;
      try {
        await this.request({
          url: url,
          method: method,
          data: {
            ...this.form,
            price: parseInt(this.form.price),
          },
          messages: {
            500: true,
            404: this.$t("pack.errors.not_found"),
            201: this.$t("pack.add.success"),
            200: this.$t("pack.edit.success"),
            400: this.$t("error_codes.bad_request"),
          },
        });
        this.$emit("success");

        if (!this.pack.id) {
          // close dialog
          this.dialog.display = false;
          // reset form validation
          this.$v.form.$reset();
        }
      } catch (error) {
        // empty
      }
      this.loading = false;
    },
    async setApplicationItems() {
      try {
        const response = await this.request({
          url: "/api/applications?fields=id,title",
        });
        this.items.applications = response.data.data;
      } catch (error) {
        //empty
      }
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  computed: {
    applicationErrors() {
      const errors = [];

      if (!this.$v.form.application.$dirty) return errors;

      !this.$v.form.application.required &&
        errors.push(this.$t("pack.fields.application.required"));
      return errors;
    },
    titleErrors() {
      const errors = [];

      if (!this.$v.form.title.$dirty) return errors;

      !this.$v.form.title.required &&
        errors.push(this.$t("pack.fields.title.required"));
      !this.$v.form.title.maxLength &&
        errors.push(
          this.$t("pack.fields.title.maxLength", {
            max: this.$v.form.title.$params.maxLength.max,
          })
        );
      typeof this.$v.form.title.isUnique === "boolean" &&
        !this.$v.form.title.isUnique &&
        errors.push(this.$t("pack.fields.title.exists"));
      return errors;
    },
    colorErrors() {
      const errors = [];

      if (!this.$v.form.color.$dirty) return errors;

      !this.$v.form.color.required &&
        errors.push(this.$t("pack.fields.color.required"));
      !this.$v.form.color.color &&
        errors.push(this.$t("pack.fields.color.invalid"));
      return errors;
    },
    codeErrors() {
      const errors = [];

      if (!this.$v.form.code.$dirty) return errors;

      !this.$v.form.code.required &&
        errors.push(this.$t("pack.fields.code.required"));
      !this.$v.form.code.maxLength &&
        errors.push(
          this.$t("pack.fields.code.maxLength", {
            max: this.$v.form.code.$params.maxLength.max,
          })
        );
      !this.$v.form.code.isUnique &&
        errors.push(this.$t("pack.fields.code.exists"));
      return errors;
    },
    priceErrors() {
      const errors = [];

      if (!this.$v.form.price.$dirty) return errors;

      !this.$v.form.price.required &&
        errors.push(this.$t("pack.fields.price.required"));
      !this.$v.form.price.price &&
        errors.push(this.$t("pack.fields.price.invalid"));
      return errors;
    },
    quantityErrors() {
      const errors = [];

      if (!this.$v.form.quantity.$dirty) return errors;

      !this.$v.form.quantity.required &&
        errors.push(this.$t("pack.fields.quantity.required"));
      !this.$v.form.quantity.quantity &&
        errors.push(this.$t("pack.fields.quantity.invalid"));
      return errors;
    },
    unitErrors() {
      const errors = [];

      if (!this.$v.form.unit.$dirty) return errors;

      !this.$v.form.unit.required &&
        errors.push(this.$t("pack.fields.unit.required"));
      !this.$v.form.unit.unit &&
        errors.push(this.$t("pack.fields.unit.invalid"));
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.form.description.$dirty) return errors;

      !this.$v.form.description.required &&
        errors.push(this.$t("pack.fields.description.required"));
      !this.$v.form.description.maxLength &&
        errors.push(
          this.$t("pack.fields.description.maxLength", {
            max: this.$v.form.description.$params.maxLength.max,
          })
        );
      return errors;
    },
  },
};
</script>