<template>
  <div>
    <pack-list></pack-list>
  </div>
</template>

<script>
import PackList from "./../../../components/packs/admin/list.vue";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t('pack.title')
    }
  },
  components: {
    PackList
  },
};
</script>