export const PackFormConst = {
  title: null,
  code: null,
  application: null,
  price: null,
  quantity: null,
  unit: null,
  description: null,
  active: false,
  color: '#000000'
};
