var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h6 font-weight-medium mb-4"},[_vm._v(_vm._s(_vm.$t("pack.title")))]),_c('v-card',{staticClass:"rounded-md mb-4"},[_c('div',{staticClass:"d-flex align-center justify-start px-4 pt-3 pb-2 flex-wrap"},[_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"depressed":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refreshItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t("btn.refresh"))+" ")],1),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"depressed":"","loading":_vm.loading.filter},on:{"click":function($event){return _vm.filterItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" "+_vm._s(_vm.$t("btn.filter"))+" ")],1),_c('div',{staticClass:"mb-2",class:{'mr-auto': _vm.$vuetify.breakpoint.xsOnly, 'ml-auto': _vm.$vuetify.breakpoint.smAndUp }},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addPack()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t("btn.add")))])],1)],1)],1)]),_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('pack.list.title')))])]),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading.list,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"footer-props":_vm.footerProps,"items-per-page":_vm.itemPerPage,"sort-by":"id","sort-desc":"","server-items-length":_vm.meta.total},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm._.isEmpty(_vm.items))?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('v-hover',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',[_c('td',{staticStyle:{"min-width":"160px"}},[_c('span',[_vm._v(_vm._s(item.application.title))])]),_c('td',[(item.active)?_c('v-chip',{staticClass:"table-chips",attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.$t("pack.fields.active.activated")))]):_c('v-chip',{staticClass:"table-chips",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t("pack.fields.active.deactivated"))+" ")])],1),_c('td',[_c('span',[_vm._v(_vm._s(item.title))])]),_c('td',[_c('span',[_vm._v(_vm._s(item.quantity)+" "+_vm._s(item.unit.toUpperCase()))])]),_c('td',[_c('span',[_vm._v(_vm._s(item.price))])]),_c('td',{staticStyle:{"min-width":"150px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.editPack(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pack.btn.edit.title')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.deletePack(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pack.btn.delete.title')))])])],1)])]}}],null,true)})]})],2)]}}:null],null,true)})],1),_c('pack-form',{attrs:{"dialog":_vm.dialog.form,"pack":_vm.pack,"form":_vm.form},on:{"success":_vm.refreshItems}}),_c('pack-confirm-delete',{attrs:{"dialog":_vm.dialog.delete},on:{"confirm":_vm.confirmDeletePack}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }